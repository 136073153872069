import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import feathers from "./data/feathers";
import tritone from "./data/tritone";
import loader from "./data/aloader";
import socials from "./data/socials";
import manualicons from "./data/manualicons";
import AppContext from "./AppContext.js";
import { ReactComponent as Lock } from "./images/lock.svg";
import { ReactComponent as Folder } from "./images/grid.svg";

const Menu = () => {
  let sets = [feathers, socials, tritone];
  return (
    <AppContext.Consumer>
      {({ currentSet, setCurrentSet, darkMode, ipc }) => (
        <>
          <Wrap darkMode={darkMode}>
            <div className="intro">
              <h1>ICONSVG</h1>
              <p>Quick customizable icons for your projects</p>
            </div>
            <ul className="set">
              {sets.map((s, i) => {
                return (
                  <li
                    key={i}
                    className={currentSet.name === s.name ? "active" : ""}
                    onClick={() => setCurrentSet(s)}
                  >
                    {s.isPremium && !(ipc.indexOf(s.id) > -1) ? (
                      <Lock />
                    ) : (
                      <Folder />
                    )}
                    <span>{s.name}</span>
                  </li>
                );
              })}
            </ul>
            <Footer />
          </Wrap>
        </>
      )}
    </AppContext.Consumer>
  );
};

export default Menu;

const Wrap = styled.div`
  width: 200px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.05);
  background: ${props => (props.darkMode ? "rgb(25, 26, 30)" : "#f7f7f7")};
  .intro {
    padding: 20px 14px;
    margin-bottom: 16px;
    h1 {
      font-size: 18px;
      margin-bottom: 8px;
      color: ${props => (props.darkMode ? "#fff" : "#000")};
    }
    p {
      font-size: 15px;
      line-height: 1.5;
      color: ${props =>
        props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.6)"};
    }
  }

  ul.set > li {
    margin: 1px 8px;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 4px;
    /* color: #444; */
    color: ${props =>
      props.darkMode ? "rgba(255, 255, 255, 0.9);" : "rgba(0, 0, 0, 0.8)"};
    display: flex;
    align-items: center;
    &.active {
      /* background: #666; */
      background: #0094dd;
      color: #fff;
      font-weight: 600;
      svg {
        g {
          stroke: white;
          rect {
            fill: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    &:not(.active):hover {
      background: ${props => (props.darkMode ? "rgba(0, 0, 0, 0.3);" : "#eee")};
    }
    svg {
      margin-right: 10px;
      g rect {
        fill: rgba(0, 0, 0, 0.15);
      }
    }
  }
`;
