import React, { Component } from "react";
import styled from "styled-components";
import me from "./images/me.jpg";
import About from "./About";
import AppContext from "./AppContext.js";
import { ReactComponent as ToggleOff } from "./images/toggle-off.svg";
import { ReactComponent as ToggleOn } from "./images/toggle-on.svg";

export default class Footer extends Component {
  state = {
    showAbout: false
  };

  toggleAbout = () => {
    this.setState({
      showAbout: !this.state.showAbout
    });
  };
  render() {
    return (
      <AppContext.Consumer>
        {({ darkMode, toggleDarkMode }) => (
          <>
            <Wrap darkMode={darkMode}>
              <p className="darkmode">
                <span>Dark mode</span>
                <a onClick={() => toggleDarkMode()}>
                  {darkMode ? <ToggleOn /> : <ToggleOff />}
                </a>
              </p>
              <div className="listing">
                <a href="#" onClick={() => this.toggleAbout()}>
                  What is this?
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://airtable.com/shrsnhEfSLu5IYK73"
                >
                  Suggest an icon
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.buymeacoffee.com/gaddafirusli"
                >
                  Buy me a coffee
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.twitter.com/gaddafirusli"
                >
                  Made by @gaddafirusli
                </a>
              </div>
            </Wrap>
            {this.state.showAbout && <About toggleAbout={this.toggleAbout} />}
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

const Wrap = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 14px 16px;
  width: 200px;
  p.darkmode {
    padding: 15px 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props =>
      props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.8)"};
    a {
      color: ${props =>
        props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.8)"};
      cursor: pointer;
      transform: scale(1.1);
      &:hover {
        color: #2692ee;
      }
    }
  }
  .listing {
    padding-top: 10px;
    border-top: ${props =>
      props.darkMode
        ? "1px solid rgba(255, 255, 255, 0.2);"
        : "1px solid rgba(0, 0, 0, 0.1)"};
    a {
      font-size: 15px;
      text-decoration: none;
      color: ${props =>
        props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.8)"};
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        color: #2692ee;
      }
      span {
        margin-right: 8px;
      }
    }
  }
`;
