import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import ReactGA from "react-ga";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as Download } from "./images/download.svg";
import { ReactComponent as Code } from "./images/code.svg";
import { ReactComponent as Check } from "./images/check.svg";
import { ReactComponent as Loader } from "./images/loader.svg";
import AppContext from "./AppContext.js";

export default class ActionBar extends Component {
  state = {
    showCopied: false,
    showDownloading: false
  };

  copySVG = () => {
    ReactGA.event({
      category: "Clicks",
      action: "Copy",
      label: `${this.props.setName} / ${this.props.name}`
    });
    this.setState({ showCopied: true }, this.toggleCopyMessage);
  };

  toggleCopyMessage = () => {
    setTimeout(() => {
      this.setState({ showCopied: false });
    }, 1500);
  };

  downloadSVG = () => {
    ReactGA.event({
      category: "Clicks",
      action: "Download",
      label: `${this.props.setName} / ${this.props.name}`
    });
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:image/svg+xml;charset=utf-8," + encodeURIComponent(this.props.svg)
    );
    element.setAttribute("download", this.props.name + ".svg");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.setState({ showDownloading: true }, this.toggleDownloadMessage);
  };

  toggleDownloadMessage = () => {
    setTimeout(() => {
      this.setState({ showDownloading: false });
    }, 1500);
  };

  render = () => {
    let showCopied = this.state.showCopied;
    let showDownloading = this.state.showDownloading;
    return (
      <AppContext.Consumer>
        {({ darkMode }) => (
          <>
            <Bar darkMode={darkMode}>
              <button onClick={() => this.downloadSVG()}>
                {showDownloading && (
                  <>
                    <Loader />
                    Downloading...
                  </>
                )}
                {!showDownloading && (
                  <>
                    <Download />
                    Download
                  </>
                )}
              </button>
              <CopyToClipboard
                text={this.props.code}
                onCopy={() => this.copySVG()}
              >
                <button>
                  {showCopied && (
                    <>
                      <Check />
                      Copied!
                    </>
                  )}
                  {!showCopied && (
                    <>
                      <Code />
                      Copy {this.props.type} code
                    </>
                  )}
                </button>
              </CopyToClipboard>
            </Bar>
          </>
        )}
      </AppContext.Consumer>
    );
  };
}

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Bar = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  padding: 12px 20px;
  background: ${props => (props.darkMode ? "rgb(25, 26, 30)" : "#F7f7f7")};
  justify-content: space-between;
   /* border-top: ${props =>
     props.darkMode ? "1px solid #101115" : "1px solid #ccc"}; */
  color: #333;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  button {
    /* width: 48.5%; */
    font-size: 14px;
    font-weight: 600;
    background: ${props => (props.darkMode ? "#2A2C34" : "#fff")};
    padding: 12px 10px;
    cursor: pointer;
    border-radius: 4px;
    color: ${props => (props.darkMode ? "#eee" : "#333")};
    border: ${props =>
      props.darkMode ? "1px solid transparent" : "1px solid #ddd"};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s ease-out;
    outline: none;
 
    /* box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.03); */
    &:first-child{
      background:#0094dd;
      color:#fff;
      border:0;
      flex:0.47;
      /* width: 48.5%; */
    }
    &:last-child{
      flex:0.53;
      margin-left: 10px;
    }
    &:hover {
      border-color: ${props => (props.darkMode ? "transparent" : "#97c6ec")};
      color: #0094dd;
      transition: all 0.15s ease-out;
    }
    svg {
      margin-right: 4px;
      stroke: currentColor;
      &.loader {
        animation: ${spin} 2s linear infinite;
      }
    }
  }
`;
