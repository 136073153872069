const socials = {
  id: "sb00001",
  name: "Brand logo",
  type: "fill",
  isPremium: false,
  size: 32,
  viewBox: 24,
  cornerRadius: null,
  fillColors: ["#000"],
  preCode: "",
  postCode: "",
  disclaimer:
    "All original logos and brands are trademarks of their respective owners.",
  content: [
    {
      title: "Base",
      icons: [
        {
          name: "facebook",
          code:
            '<path class="base" d="M24 12.07C24 5.41 18.63 0 12 0S0 5.4 0 12.07C0 18.1 4.39 23.1 10.13 24v-8.44H7.08v-3.49h3.04V9.41c0-3.02 1.8-4.7 4.54-4.7 1.31 0 2.68.24 2.68.24v2.97h-1.5c-1.5 0-1.96.93-1.96 1.89v2.26h3.32l-.53 3.5h-2.8V24C19.62 23.1 24 18.1 24 12.07"/>',
          tags: ["fb"]
        },
        {
          name: "facebook-2",
          code:
            '<path class="base" d="M22.5 0c.83 0 1.5.67 1.5 1.5v21c0 .83-.67 1.5-1.5 1.5h-6v-9h3l.75-3.75H16.5v-1.5c0-1.5.75-2.25 2.25-2.25h1.5V3.75h-3c-2.76 0-4.5 2.16-4.5 5.25v2.25h-3V15h3v9H1.5A1.5 1.5 0 0 1 0 22.5v-21C0 .67.67 0 1.5 0h21z"/>',
          tags: ["fb"]
        },
        {
          name: "twitter",
          code:
            '<path class="base" d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "instagram",
          code:
            '<path class="base" d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z"/>',
          tags: ["ig", "gram"]
        },
        {
          name: "youtube",
          code:
            '<path class="base" d="M12.04 3.5c.59 0 7.54.02 9.34.5a3.02 3.02 0 0 1 2.12 2.15C24 8.05 24 12 24 12v.04c0 .43-.03 4.03-.5 5.8A3.02 3.02 0 0 1 21.38 20c-1.76.48-8.45.5-9.3.51h-.17c-.85 0-7.54-.03-9.29-.5A3.02 3.02 0 0 1 .5 17.84c-.42-1.61-.49-4.7-.5-5.6v-.5c.01-.9.08-3.99.5-5.6a3.02 3.02 0 0 1 2.12-2.14c1.8-.49 8.75-.51 9.34-.51zM9.54 8.4v7.18L15.82 12 9.54 8.41z"/>',
          tags: ["video", "tv"]
        },
        {
          name: "youtube-2",
          code:
            '<path class="base" d="M19.62 11.43c2.35 0 4.26 1.9 4.37 4.27l.01.21v3.6c0 2.4-1.85 4.37-4.17 4.48l-.21.01H4.38a4.43 4.43 0 0 1-4.37-4.27L0 19.52v-3.6c0-2.4 1.85-4.37 4.17-4.48H19.62zm-1.15 3.64c-.54 0-.98.17-1.33.5-.3.3-.47.68-.5 1.12l-.01.2v2.8c0 .59.15 1.06.46 1.4.32.34.75.51 1.29.51.6 0 1.06-.16 1.36-.48.27-.29.42-.7.45-1.23l.01-.21v-.32h-1.25v.28c0 .37-.04.6-.12.71-.08.11-.22.16-.42.16-.2 0-.33-.06-.41-.19-.07-.1-.1-.28-.12-.52V18.47h2.32V16.9c0-.59-.15-1.04-.45-1.35-.3-.32-.72-.47-1.28-.47zm-9.14.15H8.12v5.2c0 .36.07.64.21.82.14.19.35.28.63.28.23 0 .47-.06.71-.2.2-.1.38-.24.56-.43l.14-.14v.68h1.21v-6.21h-1.2v4.72c-.12.13-.25.24-.39.33a.68.68 0 0 1-.33.13c-.12 0-.2-.03-.25-.1a.44.44 0 0 1-.07-.21V15.22zm4.4-2.2H12.5v8.4h1.22v-.47a1.42 1.42 0 0 0 1.15.56c.37 0 .65-.12.83-.35.17-.2.26-.48.28-.83l.01-.18V16.7c0-.5-.1-.9-.3-1.16-.2-.26-.5-.4-.88-.4-.2 0-.38.05-.57.15-.13.08-.26.18-.39.3l-.13.13v-2.7zm-6.15 0h-4.2v1.22H4.8v7.19h1.37v-7.2h1.41v-1.22zm6.65 3.11c.17 0 .3.05.38.16.07.09.11.2.12.36V19.97c0 .2-.03.35-.1.43-.07.1-.17.14-.32.14a.7.7 0 0 1-.49-.2l-.1-.1v-3.86a.87.87 0 0 1 .26-.19.59.59 0 0 1 .25-.06zm4.19.02c.18 0 .32.06.4.17.07.09.11.23.13.41v.78h-1.07v-.63c0-.26.04-.45.12-.56.09-.11.23-.17.42-.17zM11.85 2.26c.57 0 1.03.17 1.4.5.32.29.5.66.53 1.1v3.71a1.8 1.8 0 0 1-.52 1.38c-.36.33-.85.5-1.47.5-.6 0-1.07-.18-1.43-.52-.32-.3-.5-.7-.53-1.2l-.01-.19V4c0-.53.18-.96.55-1.27.37-.31.86-.47 1.48-.47zm4.41.17v5.25c0 .16.03.28.09.35.05.07.15.1.28.1.1 0 .22-.04.38-.14.15-.1.3-.22.42-.36v-5.2h1.37v6.84h-1.37v-.75c-.25.28-.51.49-.79.63-.27.15-.53.22-.79.22-.32 0-.55-.1-.72-.3-.15-.2-.23-.51-.23-.92V2.43h1.36zM6.1 0l1 3.7h.1L8.13 0h1.57L7.9 5.43v3.84H6.39V5.6L4.55 0H6.1zm5.69 3.45a.6.6 0 0 0-.4.13.46.46 0 0 0-.16.28v3.81c0 .19.04.33.14.44.1.1.24.15.42.15a.6.6 0 0 0 .44-.16c.09-.08.14-.19.16-.32V3.95a.45.45 0 0 0-.16-.37.67.67 0 0 0-.44-.13z"/>',
          tags: ["video", "tv"]
        },
        {
          name: "behance",
          code:
            '<path class="base" d="M18.53 8.12c5.77 0 5.08 6.08 5.05 6.28v.01h-7.51c0 2.7 2.55 2.52 2.55 2.52 2.33 0 2.33-1.46 2.32-1.55h2.56c0 4.13-4.97 3.85-4.97 3.85-5.95 0-5.57-5.54-5.57-5.54v-.02c0-.37.2-5.55 5.57-5.55zM.23 4.42h7.55c2.3 0 4.1 1.27 4.1 3.87s-2.2 2.76-2.2 2.76c2.91 0 2.7 3.6 2.7 3.6 0 4.06-3.88 4.27-4.51 4.28H.24V4.43h7.54H.24zm7.55 8.06H3.56v3.87h4.01c.6-.01 1.74-.19 1.74-1.88 0-1.87-1.32-1.99-1.5-2h-.02zm10.8-2.24c-2.16 0-2.52 2.09-2.54 2.23h4.76v-.02c0-.25-.11-2.21-2.21-2.21zM7.79 7H3.56v3.24h3.96c.68 0 1.29-.22 1.29-1.73 0-1.38-.86-1.5-1-1.5h-.02zM21.4 5.28v1.79h-5.98V5.28h5.98z"/>',
          tags: ["portfolio", "showcase"]
        },
        {
          name: "dribbble",
          code:
            '<path class="base" d="M23.76 9.58A11.99 11.99 0 0 0 0 12a12.08 12.08 0 0 0 3.51 8.49 12.12 12.12 0 0 0 6.07 3.27A11.99 11.99 0 0 0 24 12a12 12 0 0 0-.24-2.42zm-1.51 2.32c-.15-.03-3.62-.78-7.14-.34a38.64 38.64 0 0 0-.9-2.01c4.04-1.66 5.69-4.03 5.7-4.06a10.2 10.2 0 0 1 2.34 6.4zm-3.48-7.6c-.03.05-1.49 2.27-5.35 3.72a52.06 52.06 0 0 0-3.83-5.98 10.23 10.23 0 0 1 9.18 2.27zM7.63 2.74a61.6 61.6 0 0 1 3.8 5.9A37.91 37.91 0 0 1 1.97 9.9c.67-3.18 2.8-5.8 5.66-7.16zM1.75 12l.01-.32c.18 0 5.25.11 10.52-1.46.3.57.58 1.15.83 1.74l-.4.12c-5.53 1.79-8.34 6.76-8.34 6.76A10.21 10.21 0 0 1 1.76 12zM12 22.25a10.2 10.2 0 0 1-6.53-2.35l.23.18s1.97-4.29 8.04-6.4l.07-.02a42.64 42.64 0 0 1 2.2 7.78 10.2 10.2 0 0 1-4.01.8zm5.73-1.75c-.1-.62-.65-3.63-2-7.32 3.31-.53 6.18.38 6.39.45a10.26 10.26 0 0 1-4.4 6.87z"/>',
          tags: ["design", "sports"]
        },
        {
          name: "tumblr",
          code:
            '<path class="base" d="M13.02 0v5.55h5.55v4.28h-5.55V16c0 1.39-.02 2.2.13 2.59.14.4.51.8.91 1.03.53.32 1.14.48 1.82.48 1.22 0 2.43-.4 3.63-1.18v3.78c-1.03.48-1.96.82-2.79 1.02-.83.2-1.73.29-2.7.29a7.36 7.36 0 0 1-4.74-1.6c-.6-.51-1-1.06-1.24-1.64a7.08 7.08 0 0 1-.34-2.5V9.82H4.44v-3.4a8.5 8.5 0 0 0 2.7-1.31 6.57 6.57 0 0 0 1.62-2.06c.41-.8.7-1.82.85-3.06H13z"/>',
          tags: ["design", "sports"]
        },
        {
          name: "spotify",
          code:
            '<path class="base" d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm4.87 17.66c-.2 0-.33-.06-.51-.18a12.03 12.03 0 0 0-6.2-1.6c-1.3 0-2.59.16-3.8.42-.19.04-.44.11-.59.11a.75.75 0 0 1-.75-.75c0-.5.29-.75.65-.82 1.48-.34 2.96-.53 4.49-.53 2.62 0 4.97.6 6.98 1.8.3.18.47.36.47.8 0 .43-.35.75-.74.75zm1.3-3.17c-.25 0-.42-.1-.6-.21a15.22 15.22 0 0 0-7.62-1.93c-1.51 0-2.83.21-3.91.5-.24.07-.37.14-.59.14a.94.94 0 0 1-.93-.95c0-.5.24-.86.74-1C6.61 10.67 8 10.4 10 10.4c3.15 0 6.18.78 8.57 2.2.4.24.55.53.55.96 0 .52-.41.94-.93.94zm1.5-3.7c-.25 0-.4-.06-.62-.18-2.18-1.3-5.55-2.02-8.8-2.02-1.63 0-3.29.16-4.8.57-.17.05-.4.14-.62.14-.64 0-1.13-.51-1.13-1.15 0-.65.4-1.02.84-1.15 1.71-.5 3.62-.74 5.7-.74 3.52 0 7.23.73 9.94 2.32.36.2.62.52.62 1.09 0 .65-.53 1.12-1.14 1.12z"/>',
          tags: ["design", "sports"]
        },
        {
          name: "apple",
          code:
            '<path class="base" d="M16.46 5.79l.3.01a5.6 5.6 0 0 1 4.38 2.38c-.1.07-2.62 1.53-2.59 4.57.04 3.63 3.19 4.84 3.22 4.86-.02.08-.5 1.72-1.66 3.41-1 1.46-2.04 2.92-3.67 2.95-1.6.03-2.13-.96-3.96-.96-1.84 0-2.42.93-3.94.99-1.57.06-2.78-1.58-3.78-3.04-2.07-2.98-3.64-8.42-1.53-12.1a5.87 5.87 0 0 1 4.97-3c1.55-.03 3.01 1.04 3.96 1.04.95 0 2.73-1.29 4.6-1.1zM16.78 0a5.3 5.3 0 0 1-1.25 3.83 4.46 4.46 0 0 1-3.56 1.7 5.03 5.03 0 0 1 1.27-3.71A5.38 5.38 0 0 1 16.78 0z"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "linkedin",
          code:
            '<path class="base" d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "pinterest",
          code:
            '<path class="base" d="M12 0a12 12 0 0 0-4.82 23c-.03-.85 0-1.85.21-2.76l1.55-6.54s-.39-.77-.39-1.9c0-1.78 1.03-3.1 2.32-3.1 1.09 0 1.62.81 1.62 1.8 0 1.09-.7 2.73-1.06 4.25-.3 1.27.63 2.31 1.89 2.31 2.27 0 3.8-2.92 3.8-6.38 0-2.63-1.77-4.6-4.99-4.6a5.68 5.68 0 0 0-5.9 5.75c0 1.05.3 1.78.78 2.35.23.27.26.37.18.67l-.25.97c-.08.3-.32.4-.6.3-1.67-.69-2.46-2.52-2.46-4.59 0-3.4 2.88-7.5 8.58-7.5 4.58 0 7.6 3.32 7.6 6.88 0 4.7-2.62 8.22-6.48 8.22-1.3 0-2.51-.7-2.93-1.5l-.84 3.3c-.26.93-.76 1.86-1.21 2.58A11.99 11.99 0 0 0 24 12 12 12 0 0 0 12 0z"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "skype",
          code:
            '<path class="base" d="M23.31 14.55A11.37 11.37 0 0 0 10.17 1 6.67 6.67 0 0 0 0 6.55c0 1.2.33 2.33.91 3.3a11.37 11.37 0 0 0 13.31 13.37c.94.5 2 .78 3.14.78A6.6 6.6 0 0 0 24 17.45c0-1.04-.25-2.03-.69-2.9zM18 17.52a5.2 5.2 0 0 1-2.33 1.74c-1 .41-2.2.62-3.57.62a8.84 8.84 0 0 1-4.1-.85c-.77-.4-1.4-.96-1.89-1.65a3.56 3.56 0 0 1-.73-2.04c0-.4.16-.77.48-1.06.31-.3.71-.44 1.2-.44.39 0 .73.12 1 .34.27.23.49.55.67.97.2.46.43.85.66 1.15.23.3.55.54.96.73a4 4 0 0 0 1.66.3c.95 0 1.73-.2 2.3-.6.58-.38.86-.84.86-1.4 0-.45-.15-.8-.45-1.07a3.17 3.17 0 0 0-1.22-.67c-.52-.16-1.22-.33-2.1-.51a17 17 0 0 1-3-.89 4.95 4.95 0 0 1-1.96-1.42 3.5 3.5 0 0 1-.75-2.26c0-.85.27-1.62.78-2.28A4.83 4.83 0 0 1 8.7 4.7a9.84 9.84 0 0 1 3.36-.52c1.03 0 1.92.11 2.67.34.76.23 1.4.55 1.9.93.5.39.87.8 1.1 1.24.24.43.36.86.36 1.28 0 .4-.15.77-.46 1.1-.32.31-.71.48-1.18.48-.42 0-.76-.1-.99-.3a3.7 3.7 0 0 1-.68-.9 3.75 3.75 0 0 0-1.03-1.26c-.38-.29-1.02-.43-1.9-.43-.83 0-1.5.16-2 .48-.47.31-.7.66-.7 1.08 0 .26.07.47.23.66.16.2.4.37.68.51a5 5 0 0 0 .92.35c.32.1.85.22 1.58.39.93.2 1.78.41 2.54.65.76.24 1.42.54 1.95.88.55.35.99.8 1.3 1.34.3.55.46 1.22.46 2 0 .92-.27 1.77-.8 2.51z"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "snapchat",
          code:
            '<path class="base" d="M12.15 23.5h-.3c-1.42 0-2.34-.66-3.22-1.3-.6-.44-1.18-.85-1.86-.97a5.9 5.9 0 0 0-.98-.08c-.57 0-1.02.1-1.35.16-.2.04-.38.07-.5.07-.15 0-.3-.03-.36-.25-.05-.2-.1-.39-.13-.57-.1-.47-.18-.76-.37-.8-2.26-.35-2.9-.84-3.04-1.18a.45.45 0 0 1-.04-.15c0-.13.08-.25.21-.27 3.47-.58 5.02-4.2 5.09-4.35v-.01c.22-.44.26-.82.13-1.14-.24-.57-1.02-.82-1.53-.99l-.34-.11c-1.02-.42-1.1-.84-1.07-1.06.07-.37.55-.62.94-.62.11 0 .2.02.28.05.47.22.88.34 1.24.34.5 0 .71-.22.74-.24l-.05-.75c-.1-1.68-.23-3.75.29-4.94A6.25 6.25 0 0 1 11.75.5h.49c.97 0 4.27.28 5.83 3.84.52 1.18.39 3.26.29 4.94v.07l-.05.68c.03.02.23.22.68.23.34 0 .73-.12 1.16-.33a.86.86 0 0 1 .36-.07c.15 0 .3.03.42.08.35.13.58.38.59.64 0 .25-.18.61-1.08.98l-.34.11c-.51.17-1.29.42-1.53 1-.13.3-.09.69.13 1.13.07.16 1.62 3.78 5.09 4.36.13.02.22.14.21.27 0 .05-.02.1-.04.15-.14.34-.78.83-3.04 1.18-.18.03-.26.28-.37.8a8.1 8.1 0 0 1-.13.56c-.05.16-.15.24-.32.24h-.03c-.12 0-.3-.02-.5-.06a6.6 6.6 0 0 0-2.34-.07c-.68.12-1.25.53-1.86.97-.88.64-1.8 1.3-3.22 1.3z"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "whatsapp",
          code:
            '<path class="base" d="M24 11.7c0 6.45-5.27 11.68-11.78 11.68-2.07 0-4-.53-5.7-1.45L0 24l2.13-6.27a11.57 11.57 0 0 1-1.7-6.04C.44 5.23 5.72 0 12.23 0 18.72 0 24 5.23 24 11.7M12.22 1.85c-5.46 0-9.9 4.41-9.9 9.83 0 2.15.7 4.14 1.88 5.76L2.96 21.1l3.8-1.2a9.9 9.9 0 0 0 5.46 1.62c5.46 0 9.9-4.4 9.9-9.83a9.88 9.88 0 0 0-9.9-9.83m5.95 12.52c-.08-.12-.27-.19-.56-.33-.28-.14-1.7-.84-1.97-.93-.26-.1-.46-.15-.65.14-.2.29-.75.93-.91 1.12-.17.2-.34.22-.63.08-.29-.15-1.22-.45-2.32-1.43a8.64 8.64 0 0 1-1.6-1.98c-.18-.29-.03-.44.12-.58.13-.13.29-.34.43-.5.15-.17.2-.3.29-.48.1-.2.05-.36-.02-.5-.08-.15-.65-1.56-.9-2.13-.24-.58-.48-.48-.64-.48-.17 0-.37-.03-.56-.03-.2 0-.5.08-.77.36-.26.29-1 .98-1 2.4 0 1.4 1.03 2.76 1.17 2.96.14.19 2 3.17 4.93 4.32 2.94 1.15 2.94.77 3.47.72.53-.05 1.7-.7 1.95-1.36.24-.67.24-1.25.17-1.37"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "soundcloud",
          code:
            '<path class="base" d="M1.16 16.86c.05 0 .1-.04.1-.1l.27-2.1-.27-2.16c0-.05-.05-.1-.1-.1s-.1.05-.1.1l-.24 2.16.24 2.1c0 .06.05.1.1.1zm-.89-.8c.05 0 .09-.04.1-.09l.2-1.31-.2-1.33c-.01-.06-.05-.1-.1-.1s-.09.04-.1.1L0 14.66l.18 1.31c0 .05.04.1.1.1zm1.95-3.96c0-.07-.06-.12-.12-.12s-.11.05-.12.12l-.22 2.56.22 2.46c0 .07.06.12.12.12s.11-.05.12-.11l.26-2.47-.26-2.56zm.83 5.24c.08 0 .14-.06.14-.14l.24-2.54-.24-2.63c0-.08-.06-.14-.14-.14a.14.14 0 0 0-.14.14l-.21 2.63.21 2.54c0 .08.07.14.14.14zm.96.04c.09 0 .16-.07.16-.16l.23-2.56-.23-2.44c0-.09-.07-.16-.16-.16a.16.16 0 0 0-.16.16l-.2 2.44.2 2.56c0 .1.07.16.16.16zm1.36-2.72l-.21-3.96c0-.1-.09-.18-.18-.18-.1 0-.18.07-.19.18l-.18 3.96.18 2.56c.01.1.09.18.19.18s.17-.08.18-.18l.21-2.56zm.58 2.75c.1 0 .2-.09.2-.2l.2-2.55-.2-4.87a.2.2 0 0 0-.2-.2.2.2 0 0 0-.2.2l-.18 4.87.18 2.55c0 .11.1.2.2.2zm.98-8.25c-.12 0-.22.1-.22.22l-.16 5.28.16 2.52c0 .13.1.22.22.22s.22-.1.22-.22l.19-2.52-.19-5.28c0-.12-.1-.22-.22-.22zm1 8.25c.12 0 .23-.1.23-.24l.17-2.5-.17-5.46a.24.24 0 0 0-.24-.25c-.13 0-.24.11-.24.25l-.15 5.45.15 2.5c0 .14.1.25.24.25zm.99 0c.14 0 .26-.12.26-.27l.16-2.48-.16-5.32a.26.26 0 0 0-.26-.26.26.26 0 0 0-.27.26l-.13 5.32.13 2.48c0 .15.12.27.27.27zm1.43-2.75l-.14-5.12a.29.29 0 0 0-.29-.28.29.29 0 0 0-.28.28l-.13 5.12.13 2.47a.28.28 0 0 0 .57 0l.14-2.47zm.59 2.76a.3.3 0 0 0 .3-.3l.13-2.46-.13-6.1a.3.3 0 0 0-.3-.3.3.3 0 0 0-.3.3l-.12 6.1.11 2.45c0 .17.14.3.3.3zm1-9.73a.33.33 0 0 0-.32.33l-.14 6.64.14 2.42c0 .17.15.32.32.32.18 0 .33-.15.33-.32l.14-2.42-.14-6.64a.33.33 0 0 0-.33-.33zm.93 9.73h8.18a2.95 2.95 0 1 0-1.14-5.67 5.2 5.2 0 0 0-7.08-4.4c-.22.09-.28.18-.28.35v9.37c0 .18.14.33.32.35z"/>',
          tags: ["mac", "ios"]
        },
        {
          name: "mesengger",
          code:
            '<path class="base" d="M12 0C5.37 0 0 4.97 0 11.11c0 3.5 1.74 6.62 4.47 8.65V24l4.09-2.24c1.09.3 2.24.46 3.44.46 6.63 0 12-4.97 12-11.1C24 4.96 18.63 0 12 0zm1.2 14.96l-3.06-3.26-5.97 3.26L10.73 8l3.13 3.26L19.76 8l-6.57 6.96z"/>',
          tags: ["facebook", "chat"]
        },
        {
          name: "wechat",
          code:
            '<path class="base" d="M22.7 11.92a5.7 5.7 0 0 1-.66 7.94c-.7.6-.94 1.1-.5 1.91.08.15.1.34.14.52-.7-.31-1.35-.78-2.04-.85-.69-.07-1.41.32-2.13.4a7.1 7.1 0 0 1-5.76-1.89c-3.07-2.84-2.63-7.2.92-9.52 3.16-2.07 7.8-1.38 10.02 1.49zm-7.41-7.03a6.65 6.65 0 0 1 1.38 3.52c-2.22.11-4.16.79-5.73 2.31a6.81 6.81 0 0 0-2.12 5.78c-.87-.11-1.66-.23-2.46-.3-.28-.02-.6.01-.84.14-.77.44-1.51.93-2.4 1.48.17-.73.27-1.37.46-1.98.13-.45.07-.7-.35-1C.56 12.95-.57 10.13.28 7.22c.78-2.7 2.7-4.33 5.3-5.18a8.89 8.89 0 0 1 9.7 2.85zm-1.63 7.97c-.47 0-.86.4-.84.86a.84.84 0 0 0 1.67 0 .84.84 0 0 0-.83-.86zm5.17 0c-.44 0-.81.35-.83.8-.02.47.35.85.82.85a.8.8 0 0 0 .82-.78.82.82 0 0 0-.81-.87zm-7.06-6.39c-.55.01-1.01.48-1 1.01a1 1 0 0 0 1.03.98c.56 0 1-.44.99-1a1 1 0 0 0-1.02-.99zm-6.34 0a1 1 0 0 0-1.04.97c-.01.56.42 1 .98 1.02.56.01 1.03-.42 1.05-.96a1.03 1.03 0 0 0-.99-1.03z"/>',
          tags: ["chat"]
        }
      ]
    },
    {
      title: "Square",
      icons: [
        {
          name: "facebook-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-4 7.28V4.5h-2.29c-2.1 0-3.42 1.6-3.42 3.89v1.67H8v2.77h2.29v6.67h2.85v-6.67h2.29l.57-2.77h-2.86V8.94c0-1.1.58-1.66 1.72-1.66H16z"/>',
          tags: ["fb"]
        },
        {
          name: "twitter-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-4.42 5.8a3.28 3.28 0 0 0-3.2 4.03A9.32 9.32 0 0 1 5.61 6.4a3.26 3.26 0 0 0 1.02 4.38 3.27 3.27 0 0 1-1.49-.4v.03a3.28 3.28 0 0 0 2.64 3.22 3.3 3.3 0 0 1-1.49.06 3.29 3.29 0 0 0 3.07 2.28 6.59 6.59 0 0 1-4.86 1.36 9.29 9.29 0 0 0 5.03 1.47c6.04 0 9.34-5 9.34-9.34v-.42a6.65 6.65 0 0 0 1.63-1.7c-.59.26-1.22.44-1.89.52.68-.41 1.2-1.05 1.45-1.82-.64.38-1.34.65-2.09.8a3.28 3.28 0 0 0-2.4-1.04z"/>',
          tags: ["fb"]
        },
        {
          name: "instagram-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-4.89 4.5H8.9C6.33 4.5 4.6 6.15 4.5 8.66V15.09c0 1.3.42 2.41 1.27 3.23a4.34 4.34 0 0 0 2.88 1.17l.27.01h6.16c1.3 0 2.4-.42 3.18-1.18a4.25 4.25 0 0 0 1.23-2.95l.01-.26V8.9c0-1.28-.42-2.36-1.21-3.15a4.24 4.24 0 0 0-2.92-1.23l-.26-.01zm-6.2 1.4h6.24c.9 0 1.66.26 2.2.8.47.5.77 1.18.81 1.97V15.1c0 .94-.32 1.7-.87 2.21-.5.47-1.17.74-1.98.78H8.92c-.91 0-1.67-.26-2.21-.78-.5-.5-.77-1.17-.81-2V8.88c0-.9.26-1.66.8-2.2a2.98 2.98 0 0 1 2-.78h6.45-6.23zM12 8.1a3.88 3.88 0 0 0 0 7.74 3.88 3.88 0 0 0 0-7.74zm0 1.39a2.5 2.5 0 0 1 2.48 2.48A2.5 2.5 0 0 1 12 14.45a2.5 2.5 0 0 1-2.48-2.48A2.5 2.5 0 0 1 12 9.49zm4.02-2.36a.88.88 0 1 0 0 1.76.88.88 0 0 0 0-1.76z"/>',
          tags: ["fb"]
        },
        {
          name: "youtube-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.7 7h-.6l-1.1.01c-1.48.03-3.7.1-4.46.29-.65.16-1.15.65-1.33 1.26-.18.64-.25 1.7-.29 2.46l-.02.82v.75c.03.76.1 2.09.31 2.85.18.61.68 1.1 1.33 1.26.74.19 2.87.26 4.34.29l1.41.01h1.16c1.45-.03 4-.09 4.81-.3a1.84 1.84 0 0 0 1.33-1.26c.2-.75.28-2.05.3-2.82v-.93c0-.67-.06-2.26-.3-3.13a1.84 1.84 0 0 0-1.33-1.26 25.9 25.9 0 0 0-3.88-.28L12.3 7zM10.46 9.9L14.39 12l-3.92 2.11V9.89z"/>',
          tags: ["fb"]
        },
        {
          name: "behance-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-3.76 9.74c-3.1 0-3.52 2.55-3.57 3.23l-.01.23c0 .5.1 3.16 3.33 3.3h.43c.66-.01 2.84-.19 3-2.13v-.22h-1.64v.1c-.03.23-.22.8-1.31.85h-.28c-.35 0-1.43-.13-1.53-1.35v-.18h4.81l.02-.12c.05-.64.12-3.7-3.25-3.7zM9.34 7.5H4.5v8.81h4.9c.4 0 2.75-.12 2.89-2.38v-.33c0-.43-.11-1.95-1.55-2.07h-.19s1.42-.1 1.42-1.68c0-1.52-1.07-2.29-2.45-2.35h-.18zm-2.7 4.9H9.41c.22.02.9.19.9 1.2 0 1.03-.73 1.14-1.12 1.15H6.63v-2.36zm9.63-1.37c1.17 0 1.38.9 1.41 1.24l.01.12h-3.05l.02-.1a1.6 1.6 0 0 1 1.61-1.26zM6.63 9.07H9.41c.17.03.59.18.59.91 0 .87-.34 1.03-.75 1.05H6.63V9.07zm11.44-1.05h-3.83V9.1h3.83V8.02z"/>',
          tags: ["fb"]
        },
        {
          name: "dribbble-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-8 4a8.04 8.04 0 0 0-7.37 4.89 7.95 7.95 0 0 0 .74 7.58A8.06 8.06 0 0 0 12 20a7.98 7.98 0 0 0 5.66-13.66A8 8 0 0 0 12 4zm1.16 9.12l.05-.02.21.57c.8 2.24 1.15 4.07 1.25 4.62a6.8 6.8 0 0 1-6.8-.84l-.07-.06.04-.07c.23-.45 1.65-2.92 5.32-4.2zm5.59-.04a6.84 6.84 0 0 1-2.93 4.59l-.1-.5c-.16-.83-.53-2.47-1.23-4.38a9.9 9.9 0 0 1 4.26.3zm-6.56-2.27c.2.38.38.77.55 1.16l-.27.08c-2.99.97-4.78 3.34-5.36 4.2l-.2.31-.18-.21A6.8 6.8 0 0 1 5.17 12v-.22h.38c1 0 3.77-.11 6.64-.97zm5.09-3.15a6.8 6.8 0 0 1 1.55 4.27l-.43-.08c-.79-.13-2.55-.37-4.33-.14l-.15-.35c-.14-.34-.3-.67-.45-1a8.78 8.78 0 0 0 3.8-2.7zm-8.2-1.84l.5.69c.49.7 1.28 1.9 2.04 3.25-3.28.87-6.13.83-6.3.83a6.84 6.84 0 0 1 3.76-4.77zM12 5.17c1.73 0 3.3.64 4.51 1.7l-.09.13a7.86 7.86 0 0 1-3.48 2.35l-.25-.46c-1.1-1.92-2.2-3.4-2.3-3.53.52-.12 1.06-.2 1.61-.2z"/>',
          tags: ["fb"]
        },
        {
          name: "tumblr-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.38 5H10.6c-.1.72-.26 1.32-.5 1.78-.25.47-.57.87-.97 1.2-.35.3-.9.53-1.4.7l-.22.07v1.99h1.95v4.9c0 .65.07 1.14.2 1.47.14.34.39.66.74.96a4.49 4.49 0 0 0 2.83.93 7.28 7.28 0 0 0 2.98-.63l.3-.13v-2.21c-.72.46-1.44.69-2.17.69-.4 0-.76-.1-1.08-.28a1.3 1.3 0 0 1-.55-.6 3.14 3.14 0 0 1-.08-.92v-4.18h3.32v-2.5h-3.32V5z"/>',
          tags: ["fb"]
        },
        {
          name: "spotify-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-8 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-1.23 9.54c1.75 0 3.31.4 4.66 1.2.2.12.31.24.31.53a.5.5 0 0 1-.75.44l-.08-.05a8.02 8.02 0 0 0-4.14-1.07c-.86 0-1.72.1-2.53.27l-.2.05a.9.9 0 0 1-.19.03.5.5 0 0 1-.5-.5c0-.34.19-.5.43-.55.99-.22 1.97-.35 3-.35zm-.1-2.62c2.09 0 4.11.53 5.7 1.48.27.15.37.35.37.63a.62.62 0 0 1-.95.53l-.07-.04c-1.3-.77-3.1-1.29-5.08-1.29-1.02 0-1.9.15-2.61.34l-.2.06a.63.63 0 0 1-.82-.6c0-.34.17-.57.5-.67.9-.25 1.82-.44 3.15-.44zm.16-2.74c2.35 0 4.82.48 6.62 1.54.25.14.41.34.41.73 0 .43-.35.74-.75.74a.76.76 0 0 1-.41-.12c-1.45-.87-3.7-1.34-5.87-1.34-1.09 0-2.19.1-3.2.38l-.19.05a.75.75 0 0 1-.97-.73c0-.43.27-.68.56-.76 1.14-.34 2.41-.5 3.8-.5z"/>',
          tags: ["fb"]
        },
        {
          name: "apple-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-5.08 7.63l-.23-.01c-1.06 0-2.05.7-2.6.7-.57 0-1.47-.68-2.42-.66a3.6 3.6 0 0 0-3.05 1.88c-1.3 2.3-.34 5.7.93 7.56l.22.33c.58.8 1.25 1.6 2.1 1.57.94-.04 1.3-.62 2.42-.62 1.13 0 1.45.62 2.43.6.78-.01 1.33-.55 1.83-1.22l.1-.14.33-.48c.42-.63.7-1.26.86-1.66l.1-.3L18 15l-.12-.05a3.34 3.34 0 0 1-.55-5.64l.14-.1.14-.1a3.4 3.4 0 0 0-2.53-1.47l-.16-.01-.23-.01h.23zM14.93 4c-.74.03-1.63.5-2.17 1.14-.47.56-.89 1.45-.78 2.32.83.06 1.67-.43 2.19-1.07.51-.63.86-1.51.76-2.39z"/>',
          tags: ["fb"]
        },
        {
          name: "linkedin-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zM8.95 9.4H6.16v8.1h2.8V9.4zm6.84-.19c-1.32 0-2 .63-2.38 1.16l-.13.18V9.4h-2.79l.01.49V17.5h2.78v-4.52a1.52 1.52 0 0 1 1.52-1.64c.96 0 1.37.66 1.41 1.66v4.5H19v-4.64c0-2.49-1.37-3.65-3.2-3.65zM7.58 5.5C6.62 5.5 6 6.1 6 6.9c0 .73.54 1.32 1.38 1.4h.18c.97 0 1.57-.62 1.57-1.4-.01-.8-.6-1.4-1.55-1.4z"/>',
          tags: ["fb"]
        },
        {
          name: "pinterest-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.44 4.2C8.33 4.2 6.2 7.33 6.2 9.94c0 1.58.58 2.99 1.82 3.51.2.09.39 0 .45-.23l.18-.74c.05-.2.04-.28-.07-.43l-.06-.08a2.7 2.7 0 0 1-.59-1.8c0-2.32 1.69-4.4 4.39-4.4 2.39 0 3.7 1.5 3.7 3.52 0 2.65-1.13 4.89-2.82 4.89-.93 0-1.63-.8-1.4-1.77.26-1.17.78-2.42.78-3.27 0-.75-.39-1.37-1.2-1.37-.95 0-1.72 1.01-1.72 2.37 0 .87.29 1.46.29 1.46l-1.15 5c-.34 1.5-.05 3.31-.02 3.5.01.1.15.13.2.05l.2-.25a9.67 9.67 0 0 0 1.36-2.57l.05-.19.63-2.52c.31.6 1.21 1.14 2.18 1.14 2.86 0 4.8-2.69 4.8-6.3 0-2.72-2.24-5.26-5.64-5.26z"/>',
          tags: ["fb"]
        },
        {
          name: "skype-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zM8.87 5A3.85 3.85 0 0 0 5 8.82c0 .7.2 1.36.53 1.93a6.63 6.63 0 0 0 7.76 7.8 3.9 3.9 0 0 0 1.84.45 3.85 3.85 0 0 0 3.47-5.51 6.63 6.63 0 0 0-7.67-7.9c-.6-.37-1.3-.59-2.06-.59zm3.16 2.44c.6 0 1.12.07 1.56.2.44.14.81.32 1.1.55.3.22.51.46.65.72.14.25.2.5.2.75a.9.9 0 0 1-.26.63.92.92 0 0 1-.69.28.86.86 0 0 1-.58-.17 2.16 2.16 0 0 1-.4-.53 2.19 2.19 0 0 0-.6-.73c-.22-.17-.6-.25-1.1-.25-.49 0-.88.1-1.17.28-.27.18-.4.39-.4.63 0 .15.03.28.12.39.1.11.23.21.4.3.18.08.36.15.54.2l.3.08.62.14c.54.12 1.04.25 1.48.38.45.14.83.32 1.14.52.32.2.58.47.76.78.18.32.27.7.27 1.16 0 .54-.16 1.04-.47 1.47-.3.43-.77.77-1.36 1.01-.58.24-1.28.37-2.08.37-.96 0-1.77-.17-2.4-.5a3.1 3.1 0 0 1-1.1-.96c-.28-.4-.42-.8-.42-1.19 0-.24.1-.45.28-.62a.99.99 0 0 1 .7-.26.9.9 0 0 1 .58.2c.13.1.23.25.33.43l.06.14c.12.27.25.5.39.67.13.17.32.31.56.43.24.1.57.17.97.17.55 0 1-.12 1.34-.35.34-.22.5-.5.5-.82a.8.8 0 0 0-.26-.62c-.18-.17-.42-.3-.71-.4a12.6 12.6 0 0 0-.98-.24l-.25-.05a9.91 9.91 0 0 1-1.75-.52c-.48-.2-.86-.48-1.15-.83-.28-.35-.43-.8-.43-1.31 0-.5.15-.95.46-1.34.3-.38.73-.68 1.3-.88.55-.2 1.2-.3 1.95-.3z"/>',
          tags: ["fb"]
        },
        {
          name: "snapchat-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.78 5h-.52a3.9 3.9 0 0 0-3.5 2.34 5.56 5.56 0 0 0-.22 2.27l.07 1.19-.03.03a.7.7 0 0 1-.43.11c-.22 0-.48-.06-.77-.2a.41.41 0 0 0-.17-.03c-.25 0-.55.15-.6.38-.02.13.04.39.68.64l.13.05.08.02c.32.1.8.26.95.6.07.16.06.35-.02.56l-.1.24c-.22.45-1.2 2.23-3.14 2.55a.16.16 0 0 0-.13.17c0 .03 0 .06.02.09.1.2.5.5 1.9.72.1.01.16.14.2.36l.03.12c.03.11.05.23.09.34.04.14.13.16.22.16.05 0 .12 0 .2-.02l.11-.03a3.78 3.78 0 0 1 1.46-.04c.3.05.57.2.84.37l.6.41c.48.32 1 .6 1.74.6h.18c.75 0 1.26-.28 1.74-.6l.28-.19c.38-.27.74-.52 1.16-.59a3.78 3.78 0 0 1 1.31.01l.15.03.25.04h.08c.1 0 .17-.05.2-.15l.05-.17.04-.17c.07-.32.11-.47.23-.48 1.4-.22 1.8-.52 1.9-.72a.26.26 0 0 0 .02-.1.16.16 0 0 0-.13-.16c-1.78-.29-2.76-1.83-3.07-2.42l-.11-.23c-.14-.27-.16-.5-.08-.7.11-.27.43-.42.72-.52l.38-.13.06-.02c.57-.22.68-.44.68-.6 0-.13-.11-.26-.28-.34l-.09-.04a.7.7 0 0 0-.26-.05c-.06 0-.15 0-.23.04-.21.1-.41.17-.6.2h-.12c-.28 0-.4-.13-.42-.14l.05-.94c.05-.91.07-1.91-.2-2.52A3.92 3.92 0 0 0 12.21 5z"/>',
          tags: ["fb"]
        },
        {
          name: "whatsapp-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.86 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"/>',
          tags: ["fb"]
        },
        {
          name: "soundcloud-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-6.19 8.5c-.44 0-.87.09-1.26.24-.14.06-.18.11-.19.23v6.3c0 .11.1.21.22.23h5.45c1.09 0 1.97-.89 1.97-1.98a1.98 1.98 0 0 0-2.73-1.83 3.48 3.48 0 0 0-3.46-3.19zm-2.52.85a.2.2 0 0 0-.2.2l-.08 4.1.08 1.64c0 .11.09.2.2.2a.2.2 0 0 0 .2-.14v-.06l.09-1.64-.09-4.1a.2.2 0 0 0-.2-.2zm-.67.67a.2.2 0 0 0-.2.19l-.08 3.44.09 1.65c0 .1.08.2.19.2.08 0 .15-.06.17-.14l.01-.06.1-1.65-.1-3.44a.2.2 0 0 0-.18-.2zm-2.65.22a.14.14 0 0 0-.14.13l-.11 3.28.11 1.7c0 .08.06.14.14.14.05 0 .1-.04.12-.1l.01-.03.13-1.71-.13-3.28a.14.14 0 0 0-.13-.13zm1.31-.42a.16.16 0 0 0-.16.16l-.1 3.67.1 1.68c0 .09.07.16.16.16.07 0 .13-.04.15-.11l.01-.05.12-1.68-.12-3.67c0-.09-.07-.16-.16-.16zm.67.08c-.1 0-.18.08-.18.17l-.1 3.58.1 1.66c0 .1.08.18.18.18.07 0 .14-.05.16-.12l.01-.06.1-1.66-.1-3.58c0-.1-.08-.17-.17-.17zm-2.63.96a.12.12 0 0 0-.12.12l-.13 2.67.13 1.72c0 .06.05.12.12.12.05 0 .1-.04.11-.08v-.04l.15-1.72-.14-2.67a.12.12 0 0 0-.12-.12zm1.3-.9a.15.15 0 0 0-.15.14l-.1 3.55.1 1.69c0 .08.07.15.15.15.07 0 .12-.04.14-.1l.01-.05.12-1.7-.12-3.54a.15.15 0 0 0-.15-.15zm3.34-1c-.12 0-.21.1-.21.22l-.1 4.47.1 1.62c0 .12.1.22.21.22.1 0 .18-.07.21-.16v-.06l.1-1.62-.1-4.47c0-.12-.09-.22-.2-.22zM6.67 11.9c-.05 0-.1.05-.1.1l-.14 1.64.14 1.73c0 .06.05.1.1.1a.1.1 0 0 0 .1-.06l.01-.04.15-1.73-.15-1.63c0-.06-.05-.1-.1-.1zm-.63-.11c-.05 0-.1.04-.1.09l-.14 1.76.14 1.71c0 .06.05.1.1.1a.1.1 0 0 0 .08-.06v-.04l.17-1.7-.16-1.77c0-.05-.05-.1-.1-.1zm-.64.06c-.04 0-.07.03-.08.08l-.15 1.72.15 1.65c0 .05.04.08.08.08s.08-.03.08-.08l.17-1.65-.17-1.72c0-.05-.04-.08-.08-.08zm-.63.28c-.03 0-.06.03-.06.07l-.16 1.44.16 1.42c0 .04.03.07.06.07.04 0 .06-.03.07-.07l.18-1.42-.18-1.44c0-.04-.03-.07-.07-.07zm-.59.56c-.03 0-.06.02-.06.06l-.12.9.12.87c0 .04.03.07.06.07s.06-.03.07-.06l.13-.89-.13-.9c-.01-.03-.04-.05-.07-.05z"/>',
          tags: ["fb"]
        },
        {
          name: "mesengger-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-8 5c-3.87 0-7 2.9-7 6.48a6.3 6.3 0 0 0 2.6 5.05V19l2.4-1.3c.63.17 1.3.26 2 .26 3.87 0 7-2.9 7-6.48C19 7.9 15.87 5 12 5zm4.52 4.67l-3.82 4.06-1.79-1.9-3.48 1.9 3.83-4.06 1.83 1.9 3.43-1.9z"/>',
          tags: ["fb"]
        },
        {
          name: "wechat-square",
          code:
            '<path class="base" d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-.87 11.98a5.11 5.11 0 0 0-6.68-1.01c-2.37 1.58-2.67 4.52-.62 6.45a4.7 4.7 0 0 0 3.84 1.27c.48-.05.96-.32 1.42-.27.46.05.9.37 1.36.58l-.05-.25a.49.49 0 0 0-.04-.1c-.27-.5-.15-.83.23-1.2l.1-.1a3.89 3.89 0 0 0 .44-5.37zm-4.94-4.76A5.87 5.87 0 0 0 7.72 5.3a5.07 5.07 0 0 0-3.54 3.5 4.58 4.58 0 0 0 1.78 5.01l.2.15c.24.17.3.33.25.58l-.03.1c-.12.4-.19.84-.3 1.34l.99-.63.61-.38a1 1 0 0 1 .56-.1c.53.05 1.06.13 1.64.2a4.65 4.65 0 0 1 1.41-3.9 5.63 5.63 0 0 1 3.83-1.57 4.54 4.54 0 0 0-.93-2.38zm-1.09 5.4a.56.56 0 0 1 0 1.12.56.56 0 0 1-.56-.54.57.57 0 0 1 .56-.58zm3.45 0c.31 0 .55.26.54.58a.54.54 0 0 1-.55.54.55.55 0 0 1-.54-.58c.01-.3.26-.54.55-.54zm-4.7-4.32c.37-.01.67.28.68.66 0 .38-.29.68-.67.68a.67.67 0 0 1-.68-.66c0-.36.3-.68.67-.68zm-4.23 0c.37 0 .67.33.66.7a.68.68 0 0 1-.7.64.66.66 0 0 1-.65-.7.67.67 0 0 1 .7-.64z"/>',
          tags: ["fb"]
        }
      ]
    },
    {
      title: "Circle",
      icons: [
        {
          name: "facebook-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm4 7.278V4.5h-2.286c-2.1 0-3.428 1.6-3.428 3.889v1.667H8v2.777h2.286V19.5h2.857v-6.667h2.286L16 10.056h-2.857V8.944c0-1.11.572-1.666 1.714-1.666H16z"/>',
          tags: ["fb"]
        },
        {
          name: "twitter-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm3.193 7c-1.586 0-2.872 1.243-2.872 2.777 0 .217.025.43.074.633a8.251 8.251 0 0 1-5.92-2.902c-.247.41-.389.887-.389 1.397 0 .963.507 1.813 1.278 2.311a2.94 2.94 0 0 1-1.301-.348v.036c0 1.345.99 2.467 2.304 2.723a2.98 2.98 0 0 1-1.298.047c.366 1.103 1.427 1.906 2.683 1.928a5.889 5.889 0 0 1-3.567 1.19c-.231 0-.46-.014-.685-.04A8.332 8.332 0 0 0 9.903 18c5.283 0 8.172-4.231 8.172-7.901 0-.12-.002-.24-.008-.36A5.714 5.714 0 0 0 19.5 8.302a5.869 5.869 0 0 1-1.65.437 2.8 2.8 0 0 0 1.263-1.536 5.87 5.87 0 0 1-1.824.674A2.915 2.915 0 0 0 15.193 7z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "instagram-circle",
          code:
            '<path class="base" d="M12 0c6.6274 0 12 5.3726 12 12s-5.3726 12-12 12S0 18.6274 0 12 5.3726 0 12 0zm3.115 4.5h-6.23c-2.5536 0-4.281 1.6524-4.3805 4.1552L4.5 8.8851v6.1996c0 1.3004.4234 2.4193 1.2702 3.2359.7582.73 1.751 1.1212 2.8818 1.1734l.2633.006h6.1694c1.3004 0 2.389-.4234 3.1754-1.1794.762-.734 1.1817-1.7576 1.2343-2.948l.0056-.2577V8.8851c0-1.2702-.4234-2.3589-1.2097-3.1452-.7338-.762-1.7575-1.1817-2.9234-1.2343l-.252-.0056zM8.9152 5.8911h6.2299c.9072 0 1.6633.2722 2.2076.8166.4713.499.7647 1.1758.8103 1.9607l.0063.2167v6.2298c0 .9375-.3327 1.6936-.877 2.2077-.499.4713-1.176.7392-1.984.7806l-.2237.0057H8.9153c-.9072 0-1.6633-.2722-2.2076-.7863-.499-.499-.7693-1.1759-.8109-2.0073l-.0057-.2306V8.885c0-.9073.2722-1.6633.8166-2.2077.4712-.4713 1.1712-.7392 1.9834-.7806l.2242-.0057h6.2299-6.2299zM12 8.0988c-2.117 0-3.871 1.7238-3.871 3.871A3.8591 3.8591 0 0 0 12 15.8408c2.1472 0 3.871-1.7541 3.871-3.871 0-2.117-1.754-3.871-3.871-3.871zm0 1.3911c1.3609 0 2.4798 1.119 2.4798 2.4799 0 1.3608-1.119 2.4798-2.4798 2.4798-1.3609 0-2.4798-1.119-2.4798-2.4798 0-1.361 1.119-2.4799 2.4798-2.4799zm4.0222-2.3589a.877.877 0 1 0 0 1.754.877.877 0 0 0 0-1.754z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "youtube-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.294 7h-.589l-1.101.013c-1.48.024-3.709.092-4.465.285a1.836 1.836 0 0 0-1.326 1.263c-.181.644-.258 1.69-.29 2.46l-.022.815v.328l.008.426c.022.764.09 2.088.304 2.849.172.614.68 1.098 1.326 1.263.736.188 2.867.257 4.346.283L11.89 17l1.159-.008c1.453-.019 3.993-.082 4.811-.29a1.836 1.836 0 0 0 1.327-1.263c.21-.75.28-2.048.302-2.817l.01-.528-.003-.403c-.012-.67-.066-2.265-.31-3.13a1.836 1.836 0 0 0-1.326-1.263c-.661-.169-2.45-.242-3.878-.274L12.294 7zm-1.828 2.89l3.92 2.11-3.92 2.11V9.89z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "behance-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm4.237 9.742c-3.105 0-3.513 2.55-3.566 3.225l-.011.238c-.008.487.094 3.158 3.329 3.288l.434.007c.658-.01 2.84-.187 2.99-2.131l.008-.215h-1.638l-.006.094c-.032.227-.22.793-1.307.849l-.283.005c-.344-.01-1.428-.137-1.525-1.353l-.007-.181h4.82l.011-.114c.052-.643.123-3.712-3.249-3.712zM9.341 7.5H4.5v8.813h4.897c.394-.004 2.75-.123 2.892-2.383l.008-.332c-.004-.43-.12-1.946-1.557-2.067l-.186-.007s1.419-.1 1.419-1.677c0-1.514-1.07-2.284-2.457-2.343L9.34 7.5zm-2.708 4.892h2.711l.071.005c.23.026.907.19.907 1.205 0 1.031-.733 1.137-1.118 1.145H6.633v-2.355zm9.64-1.359c1.17 0 1.375.9 1.411 1.235l.008.124h-3.056l.02-.096c.08-.316.417-1.263 1.617-1.263zm-9.64-1.967l2.72.001.055.007c.167.028.59.174.59.91 0 .862-.343 1.031-.749 1.048l-.076.001h-2.54V9.066zm11.44-1.047h-3.837v1.086h3.837V8.019z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "dribbble-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 4a8.037 8.037 0 0 0-3.114.629 8.019 8.019 0 0 0-4.724 5.76 8.042 8.042 0 0 0 .467 4.725 7.99 7.99 0 0 0 1.714 2.543 7.993 7.993 0 0 0 11.314 0A7.968 7.968 0 0 0 20 12a7.995 7.995 0 0 0-.629-3.114 7.96 7.96 0 0 0-1.714-2.542 8.005 8.005 0 0 0-4.046-2.18A8.03 8.03 0 0 0 12.001 4zm1.163 9.118l.045-.015.212.57a28.14 28.14 0 0 1 1.248 4.618 6.798 6.798 0 0 1-2.668.541c-1.55 0-2.98-.516-4.126-1.387l-.073-.06.036-.07c.232-.448 1.651-2.917 5.326-4.197zm5.583-.033a6.837 6.837 0 0 1-2.928 4.582l-.092-.499a30.737 30.737 0 0 0-1.238-4.381c2.206-.353 4.117.252 4.258.298zm-6.558-2.275c.196.383.383.773.554 1.162l-.269.081c-2.992.967-4.787 3.334-5.358 4.192l-.2.317-.184-.214A6.806 6.806 0 0 1 5.168 12c0-.073.003-.144.006-.216l.378.001c.99-.009 3.766-.116 6.636-.975zm5.089-3.15a6.798 6.798 0 0 1 1.553 4.272l-.428-.081c-.789-.135-2.55-.367-4.33-.143l-.148-.353a20.456 20.456 0 0 0-.454-.99c2.698-1.102 3.794-2.686 3.807-2.705zM9.085 5.82l.49.685c.493.71 1.287 1.901 2.047 3.252-3.28.872-6.127.838-6.308.834a6.842 6.842 0 0 1 3.771-4.77zM12 5.169c1.73 0 3.309.645 4.513 1.704l-.091.126c-.295.38-1.329 1.543-3.478 2.348l-.252-.452A34.757 34.757 0 0 0 10.39 5.36 6.822 6.822 0 0 1 12 5.168z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "tumblr-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.624 5h-2.038c-.092.722-.259 1.317-.503 1.784a3.846 3.846 0 0 1-.967 1.202c-.4.334-1.053.59-1.616.768v1.983h1.947v4.91c0 .64.069 1.128.206 1.466.138.336.383.654.74.954.355.297.785.529 1.288.691.503.16.89.242 1.545.242.577 0 1.114-.058 1.61-.17.497-.113 1.052-.312 1.664-.593v-2.208c-.718.46-1.44.69-2.165.69a2.11 2.11 0 0 1-1.088-.278c-.239-.137-.458-.375-.546-.604-.064-.167-.076-.46-.077-.92v-4.18h3.313v-2.5h-3.313V5z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "spotify-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-1.227 9.537c1.75 0 3.31.401 4.653 1.206.2.117.317.236.317.532 0 .288-.234.5-.498.5-.129 0-.217-.045-.338-.119-1.16-.7-2.605-1.07-4.14-1.07-.856 0-1.717.11-2.523.278-.13.028-.296.079-.394.079a.5.5 0 0 1-.507-.503c0-.336.194-.503.436-.55a13.357 13.357 0 0 1 2.994-.353zm-.11-2.613c2.094 0 4.12.522 5.712 1.475.267.153.366.347.366.632a.622.622 0 0 1-.624.627c-.173 0-.283-.07-.4-.14-1.302-.771-3.106-1.285-5.08-1.285-1.014 0-1.888.142-2.612.335-.156.043-.243.09-.389.09a.626.626 0 0 1-.625-.628c0-.34.165-.574.498-.668.9-.247 1.817-.438 3.154-.438zm.163-2.749c2.35 0 4.82.484 6.626 1.544.243.138.412.346.412.726 0 .437-.35.75-.754.75-.163 0-.26-.04-.413-.123-1.448-.868-3.695-1.347-5.868-1.347-1.085 0-2.186.11-3.196.385-.116.029-.263.087-.41.087a.75.75 0 0 1-.754-.764c0-.435.27-.68.56-.765 1.14-.336 2.413-.493 3.797-.493z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "apple-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm2.919 7.628l-.455-.003c-.975.085-1.862.69-2.367.69-.58 0-1.48-.67-2.431-.653-1.252.019-2.405.74-3.05 1.881-1.299 2.295-.332 5.696.935 7.559l.224.324c.572.812 1.247 1.606 2.102 1.573.934-.038 1.286-.615 2.415-.615 1.128 0 1.445.615 2.433.596 1.004-.019 1.64-.928 2.254-1.842.711-1.058 1.004-2.082 1.021-2.134l-.115-.052c-.42-.205-1.845-1.037-1.862-2.981-.019-1.9 1.522-2.812 1.59-2.858-.783-1.166-1.962-1.422-2.535-1.475l-.16-.01zM14.934 4c-.741.03-1.638.502-2.17 1.136-.478.563-.894 1.459-.783 2.32.827.066 1.67-.428 2.186-1.061.514-.635.862-1.517.767-2.395z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "linkedin-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8.951 9.404H6.165V17.5H8.95V9.404zm6.841-.192c-1.324 0-1.993.629-2.385 1.156l-.127.181V9.403h-2.786l.01.484c.006.636.007 1.748.005 2.93l-.015 4.683h2.786v-4.522c0-.242.018-.484.092-.657.202-.483.66-.984 1.43-.984.955 0 1.367.666 1.408 1.662l.003.168V17.5H19v-4.643c0-2.487-1.375-3.645-3.208-3.645zM7.576 5.5C6.623 5.5 6 6.105 6 6.899c0 .73.536 1.325 1.378 1.392l.18.006c.971 0 1.577-.621 1.577-1.398C9.116 6.105 8.53 5.5 7.576 5.5z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "pinterest-circle",
          code:
            '<path class="base" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.564 4.2C8.334 4.2 6.2 7.33 6.2 9.941c0 1.581.58 2.987 1.823 3.51.204.087.387.004.446-.23l.182-.737c.05-.197.041-.285-.066-.432l-.062-.08c-.359-.436-.588-1.001-.588-1.802 0-2.322 1.683-4.402 4.384-4.402 2.39 0 3.704 1.508 3.704 3.522 0 2.65-1.136 4.887-2.822 4.887-.932 0-1.629-.795-1.406-1.77.268-1.165.786-2.42.786-3.262 0-.752-.391-1.38-1.2-1.38-.953 0-1.718 1.017-1.718 2.38 0 .867.284 1.453.284 1.453l-1.145 5.008c-.34 1.487-.051 3.309-.027 3.492.015.11.15.136.212.054l.187-.252c.339-.468 1.036-1.502 1.366-2.568l.055-.188c.109-.409.626-2.526.626-2.526.31.61 1.213 1.145 2.175 1.145 2.862 0 4.804-2.693 4.804-6.298 0-2.726-2.237-5.265-5.636-5.265z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "skype-circle",
          code:
            '<path class="base" d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zM8.87 5A3.85 3.85 0 0 0 5 8.82c0 .7.2 1.36.53 1.93a6.63 6.63 0 0 0 7.76 7.8 3.9 3.9 0 0 0 1.84.45 3.85 3.85 0 0 0 3.47-5.51 6.63 6.63 0 0 0-7.67-7.9c-.6-.37-1.3-.59-2.06-.59zm3.16 2.44c.6 0 1.12.07 1.56.2.44.14.81.32 1.1.55.3.22.51.46.65.72.14.25.2.5.2.75a.9.9 0 0 1-.26.63.92.92 0 0 1-.69.28.86.86 0 0 1-.58-.17 2.16 2.16 0 0 1-.4-.53 2.19 2.19 0 0 0-.6-.73c-.22-.17-.6-.25-1.1-.25-.49 0-.88.1-1.17.28-.27.18-.4.39-.4.63 0 .15.03.28.12.39.1.11.23.21.4.3.18.08.36.15.54.2l.3.08.62.14c.54.12 1.04.25 1.48.38.45.14.83.32 1.14.52.32.2.58.47.76.78.18.32.27.7.27 1.16 0 .54-.16 1.04-.47 1.47-.3.43-.77.77-1.36 1.01-.58.24-1.28.37-2.08.37-.96 0-1.77-.17-2.4-.5a3.1 3.1 0 0 1-1.1-.96c-.28-.4-.42-.8-.42-1.19 0-.24.1-.45.28-.62a.99.99 0 0 1 .7-.26.9.9 0 0 1 .58.2c.13.1.23.25.33.43l.06.14c.12.27.25.5.39.67.13.17.32.31.56.43.24.1.57.17.97.17.55 0 1-.12 1.34-.35.34-.22.5-.5.5-.82a.8.8 0 0 0-.26-.62c-.18-.17-.42-.3-.71-.4a12.6 12.6 0 0 0-.98-.24l-.25-.05a9.91 9.91 0 0 1-1.75-.52c-.48-.2-.86-.48-1.15-.83-.28-.35-.43-.8-.43-1.31 0-.5.15-.95.46-1.34.3-.38.73-.68 1.3-.88.55-.2 1.2-.3 1.95-.3z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "snapchat-circle",
          code:
            '<path class="base" d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm.22 5h-.52a3.9 3.9 0 0 0-3.5 2.34 5.56 5.56 0 0 0-.22 2.27l.07 1.19-.03.03a.7.7 0 0 1-.43.11c-.22 0-.48-.06-.77-.2a.41.41 0 0 0-.17-.03c-.25 0-.55.15-.6.38-.02.13.04.39.68.64l.13.05.08.02c.32.1.8.26.95.6.07.16.06.35-.02.56l-.1.24c-.22.45-1.2 2.23-3.14 2.55a.16.16 0 0 0-.13.17c0 .03 0 .06.02.09.1.2.5.5 1.9.72.1.01.16.14.2.36l.03.12c.03.11.05.23.09.34.04.14.13.16.22.16.05 0 .12 0 .2-.02l.11-.03a3.78 3.78 0 0 1 1.46-.04c.3.05.57.2.84.37l.6.41c.48.32 1 .6 1.74.6h.18c.75 0 1.26-.28 1.74-.6l.28-.19c.38-.27.74-.52 1.16-.59a3.78 3.78 0 0 1 1.31.01l.15.03.25.04h.08c.1 0 .17-.05.2-.15l.05-.17.04-.17c.07-.32.11-.47.23-.48 1.4-.22 1.8-.52 1.9-.72a.26.26 0 0 0 .02-.1.16.16 0 0 0-.13-.16c-1.78-.29-2.76-1.83-3.07-2.42l-.11-.23c-.14-.27-.16-.5-.08-.7.11-.27.43-.42.72-.52l.38-.13.06-.02c.57-.22.68-.44.68-.6 0-.13-.11-.26-.28-.34l-.09-.04a.7.7 0 0 0-.26-.05c-.06 0-.15 0-.23.04-.21.1-.41.17-.6.2h-.12c-.28 0-.4-.13-.42-.14l.05-.94c.05-.91.07-1.91-.2-2.52A3.92 3.92 0 0 0 12.21 5z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "whatsapp-circle",
          code:
            '<path class="base" d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm.14 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "soundcloud-circle",
          code:
            '<path class="base" d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm1.81 8.2c-.44 0-.87.09-1.26.24-.14.06-.18.11-.19.23v6.3c0 .11.1.21.22.23h5.45c1.09 0 1.97-.89 1.97-1.98a1.98 1.98 0 0 0-2.73-1.83 3.48 3.48 0 0 0-3.46-3.19zm-2.52.85a.2.2 0 0 0-.2.2l-.08 4.1.08 1.64c0 .11.09.2.2.2a.2.2 0 0 0 .2-.14v-.06l.09-1.64-.09-4.1a.2.2 0 0 0-.2-.2zm-.67.67a.2.2 0 0 0-.2.19l-.08 3.44.09 1.65c0 .1.08.2.19.2.08 0 .15-.06.17-.14l.01-.06.1-1.65-.1-3.44a.2.2 0 0 0-.18-.2zm-2.65.22a.14.14 0 0 0-.14.13l-.11 3.28.11 1.7c0 .08.06.14.14.14.05 0 .1-.04.12-.1l.01-.03.13-1.71-.13-3.28a.14.14 0 0 0-.13-.13zm1.31-.42a.16.16 0 0 0-.16.16l-.1 3.67.1 1.68c0 .09.07.16.16.16.07 0 .13-.04.15-.11l.01-.05.12-1.68-.12-3.67c0-.09-.07-.16-.16-.16zm.67.08c-.1 0-.18.08-.18.17l-.1 3.58.1 1.66c0 .1.08.18.18.18.07 0 .14-.05.16-.12l.01-.06.1-1.66-.1-3.58c0-.1-.08-.17-.17-.17zm-2.63.96a.12.12 0 0 0-.12.12l-.13 2.67.13 1.72c0 .06.05.12.12.12.05 0 .1-.04.11-.08v-.04l.15-1.72-.14-2.67a.12.12 0 0 0-.12-.12zm1.3-.9a.15.15 0 0 0-.15.14l-.1 3.55.1 1.69c0 .08.07.15.15.15.07 0 .12-.04.14-.1l.01-.05.12-1.7-.12-3.54a.15.15 0 0 0-.15-.15zm3.34-1c-.12 0-.21.1-.21.22l-.1 4.47.1 1.62c0 .12.1.22.21.22.1 0 .18-.07.21-.16v-.06l.1-1.62-.1-4.47c0-.12-.09-.22-.2-.22zM6.67 11.6c-.05 0-.1.05-.1.1l-.14 1.64.14 1.73c0 .06.05.1.1.1a.1.1 0 0 0 .1-.06l.01-.04.15-1.73-.15-1.63c0-.06-.05-.1-.1-.1zm-.63-.11c-.05 0-.1.04-.1.09l-.14 1.76.14 1.71c0 .06.05.1.1.1a.1.1 0 0 0 .08-.06v-.04l.17-1.7-.16-1.77c0-.05-.05-.1-.1-.1zm-.64.06c-.04 0-.07.03-.08.08l-.15 1.72.15 1.65c0 .05.04.08.08.08s.08-.03.08-.08l.17-1.65-.17-1.72c0-.05-.04-.08-.08-.08zm-.63.28c-.03 0-.06.03-.06.07l-.16 1.44.16 1.42c0 .04.03.07.06.07.04 0 .06-.03.07-.07l.18-1.42-.18-1.44c0-.04-.03-.07-.07-.07zm-.59.56c-.03 0-.06.02-.06.06l-.12.9.12.87c0 .04.03.07.06.07s.06-.03.07-.06l.13-.89-.13-.9c-.01-.03-.04-.05-.07-.05z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "mesengger-circle",
          code:
            '<path class="base" d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm0 5c-3.87 0-7 2.9-7 6.48a6.3 6.3 0 0 0 2.6 5.05V19l2.4-1.3c.63.17 1.3.26 2 .26 3.87 0 7-2.9 7-6.48C19 7.9 15.87 5 12 5zm4.52 4.67l-3.82 4.06-1.79-1.9-3.48 1.9 3.83-4.06 1.83 1.9 3.43-1.9z"/>',
          tags: ["tweet", "bird"]
        },
        {
          name: "wechat-circle",
          code:
            '<path class="base" d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm7.13 11.98a5.11 5.11 0 0 0-6.68-1.01c-2.37 1.58-2.67 4.52-.62 6.45a4.7 4.7 0 0 0 3.84 1.27c.48-.05.96-.32 1.42-.27.46.05.9.37 1.36.58l-.05-.25a.49.49 0 0 0-.04-.1c-.27-.5-.15-.83.23-1.2l.1-.1a3.89 3.89 0 0 0 .44-5.37zm-4.94-4.76A5.87 5.87 0 0 0 7.72 5.3a5.07 5.07 0 0 0-3.54 3.5 4.58 4.58 0 0 0 1.78 5.01l.2.15c.24.17.3.33.25.58l-.03.1c-.12.4-.19.84-.3 1.34l.99-.63.61-.38a1 1 0 0 1 .56-.1c.53.05 1.06.13 1.64.2a4.65 4.65 0 0 1 1.41-3.9 5.63 5.63 0 0 1 3.83-1.57 4.54 4.54 0 0 0-.93-2.38zm-1.09 5.4a.56.56 0 0 1 0 1.12.56.56 0 0 1-.56-.54.57.57 0 0 1 .56-.58zm3.45 0c.31 0 .55.26.54.58a.54.54 0 0 1-.55.54.55.55 0 0 1-.54-.58c.01-.3.26-.54.55-.54zm-4.7-4.32c.37-.01.67.28.68.66 0 .38-.29.68-.67.68a.67.67 0 0 1-.68-.66c0-.36.3-.68.67-.68zm-4.23 0c.37 0 .67.33.66.7a.68.68 0 0 1-.7.64.66.66 0 0 1-.65-.7.67.67 0 0 1 .7-.64z"/>',
          tags: ["tweet", "bird"]
        }
      ]
    }
  ]
};
export default socials;
