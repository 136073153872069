import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Next } from "./images/next.svg";
import { ReactComponent as Prev } from "./images/prev.svg";

const Gallery = ({ images }) => {
  const [index, setIndex] = useState(0);

  const navigate = direction => {
    let max_index = images.length - 1;
    let new_index = direction === "next" ? index + 1 : index - 1;
    if (new_index > max_index) {
      setIndex(0);
    } else if (new_index < 0) {
      setIndex(max_index);
    } else {
      setIndex(new_index);
    }
  };

  const navigateTo = index => {
    setIndex(index);
  };

  return (
    <Content>
      <View>
        <div className="wrapper">
          {images.map((img, i) => {
            return (
              <img
                key={i}
                className={index == i ? "active" : ""}
                src={img}
                alt="x"
              />
            );
          })}
        </div>
        <button className="prev" onClick={() => navigate("prev")}>
          <Prev />
        </button>
        <button className="next" onClick={() => navigate("next")}>
          <Next />
        </button>
      </View>
      {images.length > 1 && (
        <div className="dots">
          {images.map((img, i) => {
            return (
              <button
                key={i}
                className={index == i ? "active" : ""}
                onClick={() => navigateTo(i)}
              >
                •
              </button>
            );
          })}
        </div>
      )}
    </Content>
  );
};

export default Gallery;
const Content = styled.div`
  button {
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }
  .dots {
    text-align: center;
    button {
      font-size: 40px;
      color: #eee;
      &:hover {
        color: #ccc;
        transition: all linear 0.2s;
      }
      &.active {
        color: #0094dd;
      }
    }
  }
`;
const View = styled.div`
  position: relative;
  .wrapper {
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  }
  button {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 20px;
    z-index: 4;
    color: #000;
    opacity: 0.3;
    &:hover {
      /* background: rgba(0, 0, 0, 0.05); */
      opacity: 0.9;
      transition: all linear 0.2s;
    }
    &.prev {
      left: 0;
      width: 50%;
      text-align: left;
    }
    &.next {
      right: 0;
      width: 50%;
      text-align: right;
    }
  }
  .wrapper {
    img {
      /* height: 500px; */
      display: none;
      &.active {
        display: block;
      }
    }
  }
`;
