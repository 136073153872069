import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Lock } from "./images/lock.svg";
import { ReactComponent as Block } from "./images/block.svg";
import { ReactComponent as Check } from "./images/check.svg";
import License from "./License";
import Gallery from "./Gallery";
import AppContext from "./AppContext.js";

const LicenseForm = () => {
  const [showInput, setShowInput] = useState(false);

  return (
    <AppContext.Consumer>
      {({ currentSet, darkMode, verifyLicense, ipc }) => (
        <>
          <Container>
            <Wall darkMode={darkMode}>
              <div className="gallery">
                {currentSet.images && <Gallery images={currentSet.images} />}
              </div>
              <div className="text">
                <h2>
                  {currentSet.name} <Lock />
                </h2>
                <p>{currentSet.description}</p>
                {/* <p className="flex">
                  <Block />
                  <span>{currentSet.customization}</span>
                </p> */}
                <a
                  className="button"
                  href={currentSet.gumroad.link}
                  target="_blank"
                >
                  Get an access code <span>•</span> {currentSet.gumroad.price}
                </a>
                <ul>
                  <li>
                    <Check /> <span>One-time payment</span>
                  </li>
                  <li>
                    <Check />
                    <span>Lifetime access to {currentSet.name} set</span>
                  </li>
                  <li>
                    <Check /> <span>Include fixes and new icons</span>
                  </li>
                  <li>
                    <Check /> <span>For personal & commercial use</span>
                  </li>
                </ul>
                <p className="link" onClick={() => setShowInput(!showInput)}>
                  Have an access code?
                </p>
              </div>
            </Wall>

            {showInput && (
              <License
                toggle={() => setShowInput(!showInput)}
                currentSet={currentSet}
                verifyLicense={verifyLicense}
              />
            )}
          </Container>
        </>
      )}
    </AppContext.Consumer>
  );
};

export default LicenseForm;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 90vh;
  align-items: center;
`;

const Wall = styled.div`
  width: 900px;
  display: flex;
  .gallery {
    flex: 0 0 520px;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  .text {
    padding-left: 40px;
    padding-top: 36px;
    color: ${props => (props.darkMode ? "#ddd" : "#333")};

    h2 {
      font-size: 32px;
      font-weight: 500;
      margin: 0 0 20px;
      color: ${props => (props.darkMode ? "#eee" : "#000")};
      svg{
        vertical-align: 2px;
      }
    }
    p {
      margin-bottom: 16px;
      line-height: 1.46;
    }
    p.flex{
      display:flex;
      align-items: center;
    }
    p.flex svg{
      flex:0 0 24px;
      margin-right: 8px;
    }
    .link {
      color: #0094dd;
      cursor: pointer;
    }
    ul {
      list-style: none;
      margin-bottom: 20px;
      li {
        margin: 6px 0;
        display: flex;
        align-items: center;
        span {
          margin-left: 12px;
        }
      }
    }
  }
  .button {
    font-size: 18px;
    font-weight: 600;
    /* background: ${props => (props.darkMode ? "#2A2C34" : "#0094dd")}; */
    background: ${props => (props.darkMode ? "#0094dd" : "#0094dd")};
    padding: 16px 20px;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 6px;
    display: inline-block;
    color: ${props => (props.darkMode ? "#eee" : "#fff")};
    /* border: ${props =>
      props.darkMode ? "1px solid transparent" : "1px solid #ddd"}; */
    text-decoration: none;

    /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05),
      0 2px 2px 1px rgba(0, 0, 0, 0.03); */
    transition: all 0.15s ease-out;
    outline: none;
    &:hover {
      border-color: ${props => (props.darkMode ? "transparent" : "#97c6ec")};

      /* color: #0094dd; */
      transition: all 0.15s ease-out;
    }
    span {
      margin: 0 4px;
      opacity:0.6;
    }
  }
`;
